import TaskStatusModel from "../../../models/responses/task-status.model";
import FirebaseUsage from "../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../firebase/constants";
import ProjectModel from "../../../models/responses/project.model";
import TaskModel from "../../../models/responses/task.model";

export default async function runUpdates(oldProjectId, newProjectId, data, userId) {
    let batch: any[] = []
    let batchCount = 0

    function commitBatch(batchArray) {
        let batchObj = FirebaseUsage.batch()
        batchArray.forEach((doc) => {
            if (doc.type === 'update') {
                batchObj.update(doc.ref, doc.data)
            } else if (doc.type === 'set') {
                batchObj.set(doc.ref, doc.data)
            } else if (doc.type === 'delete') {
                batchObj.delete(doc.ref)
            }
        })
        batchObj.commit()
    }

    const updateDate = FirebaseUsage.timestamp()

    let oldProjectTasksByTaskCode = new Map()
    let oldProjectTasksByTaskId = new Map()
    const oldTasks = await FirebaseUsage.getQuery(COLLECTIONS.TASKS, ['projectId', '==', oldProjectId])
        .then(snapshot => snapshot.docs.map(doc => doc.data()))
    for (const task of oldTasks) {
        oldProjectTasksByTaskCode.set(task.task_code, {data: task})
        oldProjectTasksByTaskId.set(task.task_id, {data: task})
    }
    // const newProjectData = await FirebaseUsage.getDoc(COLLECTIONS.PROJECTS, newProjectId).then(doc => doc.data() as ProjectModel)
    const oldProjectData = await FirebaseUsage.getDoc(COLLECTIONS.PROJECTS, oldProjectId).then(doc => doc.data() as ProjectModel)

    let newProjectTasksByTaskCode = new Map()
    await FirebaseUsage.getQuery(COLLECTIONS.TASKS, ['projectId', '==', newProjectId])
        .then(snapshot => snapshot.docs.map(doc => newProjectTasksByTaskCode.set(doc.data().task_code, {data: doc.data(), docRef: doc.ref})))

    // get ledger entries from firestore
    const ledgerEntries = await FirebaseUsage.getQuery(COLLECTIONS.LEDGER_ENTRY, ['projectId', '==', oldProjectId])
        .then(snapshot => snapshot.docs.map(doc => doc.data()))

    const projectImages = await FirebaseUsage.getQuery(COLLECTIONS.IMAGES, ['projectId', '==', oldProjectId])
        .then(snapshot => snapshot.docs.map(doc => doc.data()))

    const geoData = await FirebaseUsage.getQuery(COLLECTIONS.GEO_DATA, ['projectId', '==', oldProjectId])
        .then(snapshot => snapshot.docs.map(doc => doc.data()))

    const projectFiles = await FirebaseUsage.getQuery(COLLECTIONS.PROJECT_FILES, ['projectId', '==', oldProjectId])
        .then(snapshot => snapshot.docs.map(doc => doc.data()))

    const baselines = await FirebaseUsage.getQuery(COLLECTIONS.BASELINES, ['relatesToProject', '==', oldProjectId])
        .then(snapshot => snapshot.docs.map(doc => doc.data()))

    const baselineTasks = await FirebaseUsage.getQuery(COLLECTIONS.BASELINE_TASKS, ['projectId', '==', oldProjectId])
        .then(snapshot => snapshot.docs.map(doc => doc.data()))

    const trackedMilestones = await FirebaseUsage.getQuery(COLLECTIONS.TRACKED_MILESTONES, ['projectId', '==', oldProjectId])
        .then(snapshot => snapshot.docs.map(doc => doc.data()))

    const trackedMilestonesReplacement = await FirebaseUsage.getQuery(COLLECTIONS.TRACKED_MILESTONES, ['projectId', '==', newProjectId])
        .then(snapshot => snapshot.docs.map(doc => doc.data()))

    // get project members from firestore
    const projectMembers = await FirebaseUsage.getQuery(COLLECTIONS.PROJECT_MEMBERS, ['projectId', '==', oldProjectId])
        .then(snapshot => snapshot.docs.map(doc => doc.data()))

    // get users from firestore
    let usersById = {}
    await FirebaseUsage.getCollection(COLLECTIONS.USERS)
        .then(d => d.docs.map(doc => {
            usersById[doc.id] = doc.data()
            return doc.data()
        }))

    // do task adjustments
    console.log("Doing task adjustments")
    newProjectTasksByTaskCode.forEach((value, key) => {
        let updateData: any = {}
        const newTask = value.data
        let oldProjectTask = oldProjectTasksByTaskCode.get(key)
        oldProjectTask = oldProjectTask ? oldProjectTasksByTaskCode.get(key).data : null
        if (oldProjectTask) {
            if (newTask.status === TaskStatusModel.COMPLETE) {
                if(oldProjectTask.status === TaskStatusModel.COMPLETE) {
                    updateData = {
                        ...updateData,
                        act_start_date: oldProjectTask.act_start_date ? oldProjectTask.act_start_date : newTask.act_start_date,
                        act_end_date: oldProjectTask.act_end_date ? oldProjectTask.act_end_date : newTask.act_end_date,
                        confirmedCompleteTimestamp: oldProjectTask.confirmedCompleteTimestamp,
                        taskListType: 'Confirmed complete',
                        processedFrom: oldProjectTask.processedFrom,
                        status: oldProjectTask.status,
                        declaredCompleteTimestamp: oldProjectTask.declaredCompleteTimestamp,
                        evaluated: oldProjectTask.evaluated,
                    }
                } else if (oldProjectTask.status === TaskStatusModel.DECLARED_COMPLETE) {
                    updateData = {
                        ...updateData,
                        act_start_date: oldProjectTask.act_start_date ? oldProjectTask.act_start_date : newTask.act_start_date,
                        taskListType: 'Declared complete',
                        status: oldProjectTask.status,
                        processedFrom: oldProjectTask.processedFrom,
                        declaredCompleteTimestamp: oldProjectTask.declaredCompleteTimestamp,
                        evaluated: oldProjectTask.evaluated,
                    }
                } else {
                    updateData = {
                        ...updateData,
                        taskListType: 'Declared complete',
                        processedFrom: oldProjectTask.taskListType,
                        status: 'declared complete',
                        declaredCompleteTimestamp: newTask.act_end_date,
                        evaluated: false,
                    }
                    const ledgerEntry = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY)
                    batch.push({
                        ref: ledgerEntry,
                        type: 'set',
                        data: {
                            ledgerId: ledgerEntry.id,
                            projectId: newProjectId,
                            taskId: value.docRef.id,
                            userId: "Flowbot",
                            timestamp: updateData.declaredCompleteTimestamp,
                            logTimestamp: updateDate,
                            type: 'DEC',
                            userEmail: "Flowbot"
                        }
                    })
                    batchCount += 1
                }
            } else if (newTask.status === TaskStatusModel.IN_PROGRESS) {
                if (oldProjectTask.status === TaskStatusModel.NOT_STARTED) {
                    updateData = {
                        ...updateData,
                        taskListType: 'Work in process',
                        processedFrom: oldProjectTask.taskListType,
                        enteredWorkInProcessTime: newTask.act_start_date,
                        expiryDate: newTask.expiryDate ? newTask.expiryDate : updateDate,
                    }
                    if (usersById[userId]) {
                        const ledgerEntry = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY)
                        batch.push({
                            ref: ledgerEntry,
                            type: 'set',
                            data: {
                                ledgerId: ledgerEntry.id,
                                projectId: newProjectId,
                                taskId: value.docRef.id,
                                userId: "Flowbot",
                                timestamp: newTask.act_start_date,
                                logTimestamp: updateDate,
                                type: 'STD',
                                userEmail: "Flowbot"
                            }
                        })
                        batchCount += 1
                    }
                } else if (oldProjectTask.status === TaskStatusModel.COMPLETE || oldProjectTask.status === TaskStatusModel.DECLARED_COMPLETE) {
                    updateData = {
                        ...updateData,
                        taskListType: oldProjectTask.taskListType,
                        act_start_date: oldProjectTask.act_start_date ? oldProjectTask.act_start_date : updateDate,
                        act_end_date: oldProjectTask.act_end_date ? oldProjectTask.act_end_date : updateDate,
                        processedFrom: oldProjectTask.processedFrom,
                        declaredCompleteTimestamp: oldProjectTask.declaredCompleteTimestamp,
                        confirmedCompleteTimestamp: oldProjectTask.confirmedCompleteTimestamp,
                        status: oldProjectTask.status,
                        evaluated: oldProjectTask.evaluated,
                    }
                } else {
                    updateData = {
                        ...updateData,
                        taskListType: oldProjectTask.taskListType,
                        processedFrom: oldProjectTask.processedFrom,
                        enteredWorkInProcessTime: oldProjectTask.enteredWorkInProcessTime,
                        act_start_date: oldProjectTask.act_start_date,
                        status: oldProjectTask.status,
                        expiryDate: oldProjectTask.expiryDate ?
                            oldProjectTask.expiryDate :
                            newTask.expiryDate ? newTask.expiryDate :
                                FirebaseUsage.timestamp(),
                    }
                }
            } else if (newTask.status === TaskStatusModel.NOT_STARTED) {
                if (oldProjectTask.status === TaskStatusModel.COMPLETE || oldProjectTask.status === TaskStatusModel.DECLARED_COMPLETE) {
                    updateData = {
                        ...updateData,
                        act_start_date: oldProjectTask.act_start_date,
                        act_end_date: oldProjectTask.act_end_date ? oldProjectTask.act_end_date :
                            oldProjectTask.confirmedCompleteTimestamp ? oldProjectTask.confirmedCompleteTimestamp : null,
                        declaredCompleteTimestamp: oldProjectTask.declaredCompleteTimestamp,
                        confirmedCompleteTimestamp: oldProjectTask.confirmedCompleteTimestamp,
                        taskListType: oldProjectTask.taskListType,
                        status: oldProjectTask.status,
                        processedFrom: oldProjectTask.processedFrom,
                        evaluated: oldProjectTask.evaluated,
                    }
                } else if (oldProjectTask.status === TaskStatusModel.IN_PROGRESS || oldProjectTask.status === TaskStatusModel.SUSPENDED) {
                    updateData = {
                        ...updateData,
                        taskListType: oldProjectTask.taskListType,
                        processedFrom: oldProjectTask.processedFrom,
                        enteredWorkInProcessTime: oldProjectTask.enteredWorkInProcessTime,
                        expiryDate: oldProjectTask.expiryDate,
                        status: oldProjectTask.status,
                        act_start_date: oldProjectTask.act_start_date,
                    }
                }
            }
            updateData = oldProjectTask.suspendReason ? {...updateData,
                blocked: oldProjectTask.blocked,
                suspended: oldProjectTask.suspended,
                suspendReason: oldProjectTask.suspendReason
            } : {...updateData,
                blocked: oldProjectTask.blocked,
                suspended: oldProjectTask.suspended
            }

            if (oldProjectTask.overdueReason) {
                updateData = {...updateData,
                    overdueReason: oldProjectTask.overdueReason,
                }
            }

            if (oldProjectTask.targetFinishDate) {
                updateData = {
                    ...updateData,
                    targetFinishDate: oldProjectTask.targetFinishDate,
                }
            }

            if (oldProjectTask.actualDuration) {
                updateData = {...updateData,
                    actualDuration: oldProjectTask.actualDuration,
                }
            }

            if (oldProjectTask.status === TaskStatusModel.BLOCK) {
                updateData = {...updateData,
                    status: TaskStatusModel.BLOCK,
                }
            }

            if (oldProjectTask.sprint) {
                updateData = {...updateData,
                    sprint: oldProjectTask.sprint,
                }
            }

            if (oldProjectTask.sprintCategory) {
                updateData = {...updateData,
                    sprintCategory: oldProjectTask.sprintCategory,
                }
            }

            if (oldProjectTask.interaction) {
                updateData = {...updateData,
                    interaction: oldProjectTask.interaction,
                }
            }

            if (oldProjectTask.startSprintId) {
                updateData = {...updateData,
                    startSprintId: oldProjectTask.startSprintId,
                }
            }

            if (oldProjectTask.finishSprintId) {
                updateData = {...updateData,
                    finishSprintId: oldProjectTask.finishSprintId,
                }
            }

            if (oldProjectTask.progress) {
                updateData = {...updateData,
                    progress: oldProjectTask.progress,
                }
            }

            if (oldProjectTask.taskForce.length > 0) {
                updateData = {...updateData,
                    taskForce: oldProjectTask.taskForce,
                }
            }
            if (oldProjectTask.checklist.length > 0) {
                updateData = {...updateData,
                    checklist: oldProjectTask.checklist
                }
            }
        }
        if (Object.keys(updateData).length > 0) {
            batch.push({
                ref: value.docRef,
                type: 'update',
                data: updateData
            })
            batchCount += 1
        }
    })

    if (oldProjectData.blocked) {
        let newBlocked = {}
        for (let key in oldProjectData.blocked) {
            const task = oldProjectTasksByTaskId.get(key)
            const newTask: TaskModel = newProjectTasksByTaskCode.get(task.data.task_code)?.data
            if (newTask) {
                newBlocked[newTask.task_id] = true
            }
        }
        await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, newProjectId, {blocked: newBlocked})
    }

    if (oldProjectData.suspended) {
        let newSuspended = {}
        for (let key in oldProjectData.suspended) {
            const task = oldProjectTasksByTaskId.get(key)
            const newTask: TaskModel = newProjectTasksByTaskCode.get(task.data.task_code)?.data
            if (newTask) {
                newSuspended[newTask.task_id] = true
            }
        }
        await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, newProjectId, {suspended: newSuspended})
    }

    // do project members
    console.log("Doing project members")
    projectMembers.forEach((value) => {
        if (value.userId !== userId) {
            const projectMemberRef = FirebaseUsage.getBlankDoc(COLLECTIONS.PROJECT_MEMBERS)
            batch.push({
                ref: projectMemberRef,
                type: 'set',
                data: {
                    ...value,
                    projectId: newProjectId,
                    memberId: projectMemberRef.id,
                    userId: value.userId,
                    userEmail: value.userEmail,
                }
            })
            batchCount += 1

            if (usersById[value.userId]) {
                const userDocRef = FirebaseUsage.getDocumentRef(COLLECTIONS.USERS, value.userId)
                batch.push({
                    ref: userDocRef,
                    type: 'update',
                    data: {
                        asMember: {
                            ...usersById[value.userId].asMember,
                            [newProjectId]: {
                                ...usersById[value.userId].asMember[oldProjectId],
                                projectId: newProjectId,
                                memberId: projectMemberRef.id,
                            }

                        }
                    }
                })
                batchCount += 1
            }
        }
    })

    // do tracked milestones
    console.log("Doing tracked milestones")
    await trackedMilestones.forEach((value) => {
        const trackedMilestoneRef = FirebaseUsage.getBlankDoc(COLLECTIONS.TRACKED_MILESTONES)
        if (oldProjectTasksByTaskId.has(value.taskId)) {
            batch.push({
                ref: trackedMilestoneRef,
                type: 'set',
                data: {
                    ...value,
                    projectId: newProjectId,
                    milestoneId: trackedMilestoneRef.id,
                    taskId: newProjectTasksByTaskCode.get(oldProjectTasksByTaskId.get(value.taskId).data.task_code).docRef.id,
                }
            })
            batchCount += 1
        }
    })

    // do tracked milestones replacement
    console.log("Doing tracked milestones replacement")
    await trackedMilestonesReplacement.forEach((value) => {
        const trackedMilestoneRef = FirebaseUsage.getDocumentRef(COLLECTIONS.TRACKED_MILESTONES, value.milestoneId)
        batch.push({
            ref: trackedMilestoneRef,
            type: 'delete',
            data: {}
        })
        batchCount += 1
    })

    // do ledger entries
    console.log("Doing ledger entries")
    let newLedgerMap = new Map()
    await ledgerEntries.forEach((value) => {
        if (!oldProjectTasksByTaskId.get(value.taskId)) {
            console.log('ledger entry without task', value.taskId)
            return
        }
        const newTask = newProjectTasksByTaskCode.get(oldProjectTasksByTaskId.get(value.taskId).data.task_code)
        if (newTask) {
            const ledgerEntryRef = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY)
            newLedgerMap.set(value.ledgerId, ledgerEntryRef.id)
            batch.push({
                ref: ledgerEntryRef,
                type: 'set',
                data: {
                    ...value,
                    ledgerId: ledgerEntryRef.id,
                    projectId: newProjectId,
                    taskId: newTask.docRef.id,
                }
            })
            batchCount += 1
        }
    })

    // do project images
    console.log("Doing project images")
    let projectImageMap = new Map()
    await projectImages.forEach((value) => {
        const projectImageRef = FirebaseUsage.getBlankDoc(COLLECTIONS.IMAGES)
        const newTask = newProjectTasksByTaskCode.get(oldProjectTasksByTaskId.get(value.taskId).data.task_code)
        const newLedgerId = newLedgerMap.get(value.ledgerId)
        if (newTask && newLedgerId) {
            projectImageMap.set(value.imageId, projectImageRef.id)
            batch.push({
                ref: projectImageRef,
                type: 'set',
                data: {
                    ...value,
                    projectId: newProjectId,
                    imageId: projectImageRef.id,
                    taskId: newTask.docRef.id,
                    ledgerId: newLedgerId,
                }
            })
            batchCount += 1
        }
    })

    // do project files
    console.log("Doing project files")
    await projectFiles.forEach((value) => {
        const projectImageRef = FirebaseUsage.getBlankDoc(COLLECTIONS.PROJECT_FILES)
        const newTask = newProjectTasksByTaskCode.get(oldProjectTasksByTaskId.get(value.taskId).data.task_code)
        const newLedgerId = newLedgerMap.get(value.ledgerId)
        if (newTask && newLedgerId) {
            batch.push({
                ref: projectImageRef,
                type: 'set',
                data: {
                    ...value,
                    projectId: newProjectId,
                    imageId: projectImageRef.id,
                    taskId: newTask.docRef.id,
                    ledgerId: newLedgerId,
                }
            })
            batchCount += 1
        }
    })

    // do geo data
    console.log("Doing geo data")
    await geoData.forEach((value) => {
        const geoDataRef = FirebaseUsage.getBlankDoc(COLLECTIONS.GEO_DATA)
        const newTask = newProjectTasksByTaskCode.get(oldProjectTasksByTaskId.get(value.taskId).data.task_code)
        const newImageId = projectImageMap.get(value.imageId)
        if (newTask && newImageId) {
            batch.push({
                ref: geoDataRef,
                type: 'set',
                data: {
                    ...value,
                    projectId: newProjectId,
                    geoId: geoDataRef.id,
                    taskId: newTask.docRef.id,
                    imageId: newImageId ? newImageId : null,
                }
            })
            batchCount += 1
        }
    })

    // do baselines
    console.log("Doing baselines")
    let projectBaselines: any[] = []
    let baselineLookup = new Map()
    await baselines.forEach((value) => {
        const baselineRef = FirebaseUsage.getBlankDoc(COLLECTIONS.BASELINES)
        baselineLookup.set(value.baselineId, baselineRef.id)
        batch.push({
            ref: baselineRef,
            type: 'set',
            data: {
                ...value,
                relatesToProject: newProjectId,
                baselineId: baselineRef.id,
            }
        })
        projectBaselines.push({baselineId: baselineRef.id, baselineName: value.baselineName})
        batchCount += 1
    })
    await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, newProjectId, {
        baselines: projectBaselines,
        issueCategories: oldProjectData.issueCategories,
        contractId: oldProjectData.contractId ? oldProjectData.contractId : null,
        projectOwners: oldProjectData.projectOwners ? oldProjectData.projectOwners : null,
        snapshotDataVersionId: oldProjectData.snapshotDataVersionId ? oldProjectData.snapshotDataVersionId : null,
        lastSnapshotTimestamp: oldProjectData.lastSnapshotTimestamp ? oldProjectData.lastSnapshotTimestamp : null,
    })

    // do baseline tasks
    console.log("Doing baseline tasks")
    await baselineTasks.forEach((value) => {
        const baselineTaskRef = FirebaseUsage.getBlankDoc(COLLECTIONS.BASELINE_TASKS)
        const newTask = newProjectTasksByTaskCode.get(oldProjectTasksByTaskId.get(value.taskId).data.task_code)
        if (newTask) {
            batch.push({
                ref: baselineTaskRef,
                type: 'set',
                data: {
                    ...value,
                    blTaskId: baselineTaskRef.id,
                    projectId: newProjectId,
                    baselineTaskId: baselineTaskRef.id,
                    taskId: newTask.docRef.id,
                    baselineId: baselineLookup.get(value.baselineId),
                }
            })
            batchCount += 1
        }
    })

    if (batchCount < 500) {
        commitBatch(batch)
    } else {
        for (let i = 0; i < batch.length; i += 500) {
            console.log('running update batch ' + (i + 1) + ' to ' + Math.ceil(batchCount / 500))
            commitBatch(batch.slice(i, i + 500))
        }
    }
}