import { Timestamp } from "firebase/firestore";

export enum MessageType {
  // User message
  MSG = "MSG",

  // Approve new task
  APN = "APN",

  // Task restarted
  RST = "RST",

  // Log issue
  ISS = "ISS",

  // Task renamed
  RNM = "RNM",

  // Task Images
  IMG = "IMG",

  // Constraint
  CST = "CST",

  // Task created
  NEW = "NEW",

  // Task started
  STD = "STD",

  // Task unstarted
  UST = "UST",

  // Task start confirmed
  COS = "COS",

  // Task suspended – directive
  DIR = "DIR",

  // Task blocked
  BLC = "BLC",

  // Task blocked
  UNB = "UNB",

  // Task suspended – incident
  INC = "INC",

  // Task suspended – information
  INF = "INF",

  // Task suspended – other
  OTH = "OTH",

  // Task suspended – resource
  RES = "RES",

  // Task declared complete
  DEC = "DEC",

  // Task declaration rejected
  REJ = "REJ",

  // Task complete
  COM = "COM",

  // Taskforce Join
  TFJ = "TFJ",

  // Taskforce Left
  TFL = "TFL",

  //Task suspended
  SUS = "SUS",

  //Task evaluated
  EVL = "EVL",

  //Added to taskforce
  ATF = "ATF",

  //Removed from taskforce
  RTF = "RTF",

  //Waiting to start
  WTG = "WTG",

  //Check List addition
  CHK = "CHK",

  //Task forcast changed
  FOR = "FOR",

  //Task Behind Schedule
  TBS = "TBS",

  // Task File
  FIL = "FIL",

  // Task Progress
  PRG = "PRG",

  // Task added to sprint
  SPR = "SPR",
}

export default interface MessageModel {
  timestamp: Timestamp;
  logTimestamp?: Timestamp;
  type: MessageType;
  suspendReason?: string;
  taskId: string;
  userId?: string;
  text?: string;
  userEmail?: string;
  ledgerId: string;
  projectId: string;
  attachments?: string[];
  oldName?: string;
  value?: number;
}
