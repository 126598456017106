import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Dropdown, DropdownDivider, Icon, Input, Popup} from "semantic-ui-react";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import EventTextModel from "../../../../../../models/event-text-model.enum";
import {SuspendReasons} from "../../../../../../models/suspend-reason.enum";
import {EvaluationOptions} from "../../../../../../models/evaluation-options.enum";
import TaskStatusModel from "../../../../../../models/responses/task-status.model";
import {MemberOrganizationEnum} from "../../../../../../models/member-organization.enum";
import {
    useIsUserMemberOfCurrentTaskSelector,
    useUserAsMemberForActiveProjectSelector,
    useUserSelector,
} from "../../../../../../store/selectors/authorization.selectors";
import {TaskThunk} from "../../../../../../store/thunk/task.thunk";
import {MessageType} from "../../../../../../models/responses/message.model";
import FirebaseUsage from "../../../../../../firebase/firebase.usage";
import moment from "moment";
import {useDispatch} from "react-redux";
import {SemanticICONS} from "semantic-ui-react/dist/commonjs/generic";
import TaskModel from "../../../../../../models/responses/task.model";
import {useTypedSelector} from "../../../../../../store/selectors/selectors.utils";
import styles from "./TaskContainer.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiamond, faEdit} from "@fortawesome/pro-solid-svg-icons";
import {getMilestoneColor} from "../../../../../../utils/getMilestoneColor";
import {TaskType} from "../../../../../../models/task-type";
import {useActiveTaskSelector} from "../../../../../../store/selectors/task/task.selectors";
import {COLLECTIONS} from "../../../../../../firebase/constants";
import * as taskActions from "../../../../../../store/actions/task.actions";
import {joinTaskForce, leaveTaskForce} from "../../../../../../utils/taskforce.utils";
import ProjectMembersModel from "../../../../../../models/responses/project-members.model";
import {useActiveProjectSelector} from "../../../../../../store/selectors/project.selectors";
import * as projectActions from "../../../../../../store/actions/project.actions";
import {TaskListSectionModel} from "../../../../../../models/task-list-section.model";
import {GateKeeperEum} from "../../../../../../models/gate-keeper.enum";

function AdditionalOption(props: {
  onClick: (reason: SuspendReasons) => any;
  icon: SemanticICONS;
  text: string;
  member: ProjectMembersModel;
}) {
  const handleClick = useCallback(
    (evt, data) => {
        if (!editMode) {
            evt.preventDefault();
            props.onClick(data.value);
        }
    },
    [props]
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const dispatch = useDispatch();
  const activeProject = useActiveProjectSelector();

  const addNewReason = (reason: string) => {
      if (reason === "") return;
      let reasons = [...activeProject!.issueCategories, {text: reason, reason: reason}]
          .sort((a, b) => a.text.localeCompare(b.text));
      FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, activeProject!.projectId, {
            issueCategories: reasons
        })
          .then(() => {
              console.log("Reason added");
              dispatch(projectActions.Actions.setActiveProjectReasons(reasons));
          })
          .catch((err) => console.log(err));
  }

  const deleteReason = (reason: string) => {
      let reasons = activeProject!.issueCategories
          .filter((el) => el.reason !== reason)
          .sort((a, b) => a.text.localeCompare(b.text));
        FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, activeProject!.projectId, {
            issueCategories: reasons
        })
            .then(() => {
                console.log("Reason deleted");
                dispatch(projectActions.Actions.setActiveProjectReasons(reasons));
            })
            .catch((err) => console.log(err));
  }

  return (
    <Dropdown
      pointing={"top left"}
      trigger={
        <span className="additional-task-dropdown-option-trigger" onClick={() => setOpen(true)}>
          <Icon name={props.icon} />
          {props.text}
        </span>
      }
      scrolling={!editMode}
      icon={null}
      open={open}>
      <Dropdown.Menu>
        <Dropdown.Header>Reason
            {props.member.grade === MemberOrganizationEnum.SUPER_ACTOR && !editMode &&
                <FontAwesomeIcon icon={faEdit}
                                 onClick={() => setEditMode(prevState => !prevState)}
                                 style={{
                                     fontSize: "16px",
                                     marginLeft: "10px",
                                     cursor: "pointer"}}/>}
        </Dropdown.Header>
        {activeProject!.issueCategories.map((el, key) => (
          <Dropdown.Item
            onClick={handleClick}
            key={key}
            style={editMode ? {cursor: "auto", display: "flex", justifyContent: "space-between"} : {}}
            value={el.text}>
                {el.text}
                {editMode &&
                    <Icon
                        link name={"trash alternate"}
                        onClick={() => deleteReason(el.reason)}
                        style={{marginLeft: "10px", marginRight: 0, padding: 0}}/>}
          </Dropdown.Item>
        ))}
          {editMode &&
          <Input
              style={{margin: "5px", minWidth: "calc(100% - 20px)", maxWidth: "calc(100% - 20px)"}}
              action={{content: "Add", onClick: () => addNewReason(reason)}}
              onKeyDown={(e) => {
                  if (e.keyCode === 32) {
                      e.preventDefault();
                      e.target.value = e.target.value + " ";
                      e.stopPropagation();
                  } else if (e.key === "Enter") {
                      addNewReason(reason);
                      e.target.value = "";
                    }
              }}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Add new reason"
            />}
          {editMode && <DropdownDivider />}
            {editMode &&
                <span style={{display: "flex", justifyContent: "space-between"}}>
                    <Button style={{margin: "5px", minWidth: "100px"}}
                            onClick={() => setEditMode(false)}
                    >Close</Button>
                    <Button onClick={() => setEditMode(false)}
                            style={{margin: "5px", minWidth: "100px"}} positive>Save</Button>
                </span>}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function EvaluatingOptions(props: {
  onClick: (result: EvaluationOptions) => any;
  icon: SemanticICONS;
  text: string;
}) {
  const handleClick = useCallback(
    (evt, data) => {
      evt.preventDefault();
      props.onClick(data.value);
    },
    [props]
  );
  return (
    <Dropdown
      pointing="top right"
      trigger={
        <span className="additional-task-dropdown-option-trigger">
          <Icon name={props.icon} />
          {props.text}
        </span>
      }
      icon={null}
    >
      <Dropdown.Menu>
        <Dropdown.Header>Evaluation</Dropdown.Header>
        {Object.keys(EvaluationOptions).map((el, key) => (
          <Dropdown.Item
            onClick={handleClick}
            key={key}
            value={EvaluationOptions[el]}
          >
            {EvaluationOptions[el]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

interface InjectedProps {
  children: any;
  task: TaskModel;
  setTaskOptionsOpen: (open: boolean) => void;
  type?: string;
  showPopupHeader?: boolean;
  isModalOpened: boolean;
  setIsModalOpened: (open: boolean) => void;
  setEventText: (text: EventTextModel) => void;
  setSuspendReason: (reason: SuspendReasons) => void;
  setEvaluationResult: (result: EvaluationOptions) => void;
  setDefDate: (date: string) => void;
  defDate: string;
  selectionY?: number;
}

export const TaskOptions: React.FC<InjectedProps> = (props) => {
  const { showPopupHeader = true } = props;

  const user = useUserSelector();
  const [options, setOptions] = useState<DropdownItemProps[]>([]);
  const [lowOnPage, setLowOnPage] = useState<boolean>(false);
  const isUserTaskForce = useIsUserMemberOfCurrentTaskSelector(
    props.task.task_id,
    props.type
  );
  const dispatch = useDispatch();
  const activeProject = useActiveProjectSelector();
  const activeTask = useActiveTaskSelector();
  const userAsMember = activeProject
    ? useUserAsMemberForActiveProjectSelector()
    : null;
  // const [eventText, setEventText] = useState<EventTextModel>(
  //   EventTextModel.EVENT_DESCRIPTION
  // );
  const {setIsModalOpened, setEventText, setSuspendReason, setEvaluationResult, setDefDate, defDate} = props
  const loading = useTypedSelector<boolean>(
    (state) => state.task.toolbarLoadingMap[props.task.task_id]
  );

  const commonOptions: { [name: string]: DropdownItemProps } = useMemo(
    () => ({
      joinTaskForceOptions: {
        key: "join_taskforce",
        text: EventTextModel.JOIN_TASKFORCE,
        icon: "handshake outline",
        onClick: () => optionJoinTaskforceHandler(),
      },
      leaveTaskForceOptions: {
        key: "leave_taskforce",
        text: EventTextModel.LEAVE_TASKFORCE,
        icon: "hand point left outline",
        onClick: () => optionLeaveTaskforceHandler(),
      },
      addEventLogOptions: {
        key: "add_event_log",
        text: EventTextModel.ADD_EVENT_LOG,
        icon: "check",
        onClick: () => optionAddEventLogHandler(),
      },
      startTaskOptions: {
        key: "start_task",
        text: EventTextModel.START_TASK,
        icon: "play",
        onClick: () => optionStartTaskHandler(),
      },
      resumeTaskOptions: {
        key: "resume_task",
        text: EventTextModel.RESUME_TASK,
        icon: "play",
        onClick: () => optionResumeTaskHandler(),
      },
      declareCompleteTaskOptions: {
        key: "complete_task",
        text: EventTextModel.COMPLETE_TASK,
        icon: "check",
        onClick: () => optionDeclareCompleteTaskHandler(),
      },
      unblockTaskOptions: {
        key: "unblock_task",
        text: EventTextModel.UNBLOCK_TASK,
        icon: "ban",
        onClick: () => optionUnblockTaskHandler(),
      },
      blockTaskOptions: {
        key: "block_task",
        children: (
          <AdditionalOption
            text={EventTextModel.BLOCK_TASK}
            icon="ban"
            onClick={(reason: SuspendReasons) => optionBlockTaskHandler(reason)}
            member={userAsMember!}
          />
        ),
      },
      suspendOptions: {
        key: "suspend_task",
        children: (
          <AdditionalOption
            text={EventTextModel.SUSPEND_TASK}
            icon="pause"
            onClick={(reason: SuspendReasons) =>
              optionSuspendTaskHandler(reason)
            }
            member={userAsMember!}
          />
        ),
      },
      reforecastOptions: {
        key: "reforecast_task",
        text: EventTextModel.REFORECAST_TASK,
        icon: "map pin",
        onClick: () => optionReforecastTaskHandler(),
      },
        reforecastStartOptions: {
            key: "reforecast_start",
            icon: "map pin",
            text: EventTextModel.REFORECAST_TASK_START,
            onClick: () => optionReforecastStartTaskHandler(),
        },
        approveNewTask: {
            key: "approve_new_task",
            text: EventTextModel.APPROVE_NEW_TASK,
            icon: "check",
            onClick: () => optionApproveNewTaskHandler()
        },
        logIssueOptions: {
            key: "log_issue",
            text: EventTextModel.LOG_ISSUE,
            icon: "exclamation triangle",
            children: (
                <AdditionalOption
                    text={EventTextModel.LOG_ISSUE}
                    icon="exclamation triangle"
                    onClick={(reason: SuspendReasons) =>
                        optionLogIssueHandler(reason)
                    }
                    member={userAsMember!}
                />
            ),
        },
        evaluateTaskStartOptions: {
          key: "evaluate_start",
            icon: "tag",
            text: EventTextModel.EVALUATE_TASK_START,
            onClick: () => optionEvaluateTaskStartHandler(),
        },
      evaluateCompleteTaskOptions: {
        key: "evaluate_task",
          icon: "tag",
          text: EventTextModel.EVALUATE_TASK,
          onClick: () => optionEvaluateTaskHandler(),
        // children: (
        //   <EvaluatingOptions
        //     text={EventTextModel.EVALUATE_TASK}
        //     icon="tag"
        //     onClick={(result: EvaluationOptions) =>
        //       optionEvaluateTaskHandler(result)
        //     }
        //   />
        // ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [activeProject]
  );

    const optionLogIssueHandler = useCallback((reason: SuspendReasons) => {
        setEventText(EventTextModel.LOG_ISSUE);
        setSuspendReason(reason)
        setIsModalOpened(true);
    }, []);

    const optionJoinTaskforceHandler = useCallback(() => {
        setEventText(EventTextModel.JOIN_TASKFORCE);
        // @ts-ignore
        dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
            payload: {taskId: props.task.task_id},
        });
        joinTaskForce(props.task.task_id, user!.userId!, user!.userId!, FirebaseUsage.timestamp())
            .then(() => {
                dispatch({
                    type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
                    payload: { taskId: props.task.task_id },
                });
            })
            .catch((err) => console.log(err));
        // @ts-ignore
        // dispatch(TaskThunk.joinTaskForce(props.task.task_id, (user && user.userId) || '', MessageType.TFJ, FirebaseUsage.timestamp(), props.task.taskListType));
    }, [props.task.task_id, user, dispatch]);

    const optionLeaveTaskforceHandler = useCallback(() => {
        setEventText(EventTextModel.LEAVE_TASKFORCE);
        dispatch({
            type: taskActions.ActionNames.TASK_TOOLBAR_LOADING,
            payload: {taskId: props.task.task_id},
        });
        leaveTaskForce(props.task.task_id, user!.userId!, user!.userId!, FirebaseUsage.timestamp())
            .then(() => {
                dispatch({
                    type: taskActions.ActionNames.TASK_TOOLBAR_SUCCESS,
                    payload: { taskId: props.task.task_id },
                });
            })
            .catch((err) => console.log(err));
    }, [props.task.task_id, user, dispatch]);

  const optionAddEventLogHandler = useCallback(() => {
    setEventText(EventTextModel.ADD_EVENT_LOG);
    // @ts-ignore
    dispatch(TaskThunk.addEventLog(props.task.task_id, MessageType.CHK));
  }, [props.task.task_id, dispatch]);

  const optionStartTaskHandler = useCallback(() => {
    defDate && setDefDate("");
    setIsModalOpened(true);
    setEventText(EventTextModel.START_TASK);
  }, [defDate]);

  const optionResumeTaskHandler = useCallback(() => {
    defDate && setDefDate("");
    setIsModalOpened(true);
    setEventText(EventTextModel.RESUME_TASK);
  }, [defDate]);

  const optionDeclareCompleteTaskHandler = useCallback(() => {
    defDate && setDefDate("");
    setIsModalOpened(true);
    setEventText(EventTextModel.COMPLETE_TASK);
  }, [defDate]);

  const optionUnblockTaskHandler = useCallback(() => {
    setDefDate(moment().format("YYYY-MM-DD") + "T" + moment().format("HH:mm"));
    setIsModalOpened(true);
    setEventText(EventTextModel.UNBLOCK_TASK);
  }, []);

  const optionBlockTaskHandler = useCallback((reason: SuspendReasons) => {
    setDefDate(moment().format("YYYY-MM-DD") + "T" + moment().format("HH:mm"));
    setSuspendReason(reason);
    setIsModalOpened(true);
    setEventText(EventTextModel.BLOCK_TASK);
  }, []);

  const optionSuspendTaskHandler = useCallback(
    (reason: SuspendReasons) => {
      defDate && setDefDate("");
      setSuspendReason(reason);
      setIsModalOpened(true);
      setEventText(EventTextModel.SUSPEND_TASK);
    },
    [defDate]
  );

   const optionReforecastTaskHandler = useCallback(
     () => {
       defDate && setDefDate("");
       setIsModalOpened(true);
       setEventText(EventTextModel.REFORECAST_TASK);
     },
     [defDate]
   );

   const optionReforecastStartTaskHandler = useCallback(
        () => {
        defDate && setDefDate("");
        setIsModalOpened(true);
        setEventText(EventTextModel.REFORECAST_TASK_START);
        },
        [defDate]
    );

   const optionApproveNewTaskHandler = useCallback(() => {
       console.log("Approve new task");
       // @ts-ignore
       dispatch(TaskThunk.approveNewTask(props.task.task_id))
   }, [props.task.task_id, dispatch]);

  const optionEvaluateTaskHandler = useCallback(() => {
    setDefDate(moment().format("YYYY-MM-DD") + "T" + moment().format("HH:mm"));
    // setEvaluationResult(result);
    setIsModalOpened(true);
    setEventText(EventTextModel.EVALUATE_TASK);
  }, []);

    const optionEvaluateTaskStartHandler = useCallback(() => {
        setDefDate(moment().format("YYYY-MM-DD") + "T" + moment().format("HH:mm"));
        setIsModalOpened(true);
        setEventText(EventTextModel.EVALUATE_TASK_START);
    }, []);

  useEffect(() => {
    if (user && userAsMember) {
      const newOptions: DropdownItemProps[] = [];
      newOptions.push(
        isUserTaskForce
          ? commonOptions.leaveTaskForceOptions
          : commonOptions.joinTaskForceOptions
      );
      if (
        props.task.status === TaskStatusModel.NOT_STARTED &&
        isUserTaskForce
      ) {
        if (
          props.task.checklist &&
          props.task.checklist.length > 0 &&
          props.task.checklist.some((el) => el.isChecked)
        ) {
          newOptions.push({ ...commonOptions.startTaskOptions });
        } else if (!props.task.checklist || props.task.checklist.length === 0) {
          newOptions.push({ ...commonOptions.startTaskOptions });
        }
      }
      if (
        props.task.status === TaskStatusModel.NOT_STARTED &&
        isUserTaskForce &&
        (props.task.predStatus === 1 || props.task.predStatus === 2)
      ) {
        newOptions.push({ ...commonOptions.blockTaskOptions });
      }
        if (
            props.task.status === TaskStatusModel.NOT_STARTED &&
            isUserTaskForce) {
            newOptions.push({ ...commonOptions.reforecastStartOptions });
        }

      if (
        props.task.status === TaskStatusModel.DECLARED_COMPLETE &&
        (isUserTaskForce || userAsMember.gateKeeper)
      ) {
        userAsMember.gateKeeper &&
          newOptions.push({
            ...commonOptions.evaluateCompleteTaskOptions,
          });
      }

      if (
        props.task.status === TaskStatusModel.IN_PROGRESS &&
        isUserTaskForce
      ) {
        if (userAsMember.grade !== MemberOrganizationEnum.OBSERVER) {
          if (
            props.task.checklist &&
            props.task.checklist.length > 0 &&
            props.task.checklist.every((el) => el.isChecked)
          ) {
            newOptions.push(
              { ...commonOptions.suspendOptions },
              { ...commonOptions.reforecastOptions },
              { ...commonOptions.declareCompleteTaskOptions },
                { ...commonOptions.evaluateTaskStartOptions }
            );
          } else if (
            !props.task.checklist ||
            props.task.checklist.length === 0
          ) {
            newOptions.push(
              { ...commonOptions.suspendOptions },
              { ...commonOptions.reforecastOptions },
              { ...commonOptions.declareCompleteTaskOptions },
              { ...commonOptions.evaluateTaskStartOptions }
            );
          } else {
            newOptions.push({ ...commonOptions.suspendOptions });
          }
        }
      }

      if (props.task.status === TaskStatusModel.BLOCK && isUserTaskForce) {
        newOptions.push({ ...commonOptions.unblockTaskOptions });
      }

      if (props.task.status === TaskStatusModel.SUSPENDED && isUserTaskForce) {
        newOptions.push({ ...commonOptions.resumeTaskOptions });
      }

      if (props.task.taskListType === TaskListSectionModel.PENDING &&
          (userAsMember.gateKeeper === GateKeeperEum.TaskSignOff ||
              userAsMember.gateKeeper === GateKeeperEum.Both)) {
          newOptions.push({...commonOptions.approveNewTask});
      }

      if (isUserTaskForce) newOptions.push({ ...commonOptions.logIssueOptions });

      setOptions(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    userAsMember,
    props.task.status,
    isUserTaskForce,
    props.task.checklist,
  ]);

  if (props.task.task_type !== TaskType.TT_TASK && props.task.task_type !== TaskType.TT_RSRC) {
    return (
      <FontAwesomeIcon
        className={getMilestoneColor(props.task)}
        icon={faDiamond}
      />
    );
  }

  return (
    <>
      <Popup
        className={styles.OptionWrap}
        content={
          <Dropdown
            open
            pointing={lowOnPage ? "bottom left" : "top left"}
            icon={null}
            lazyLoad
            wrapSelection={false}
          >
            <Dropdown.Menu>
              {showPopupHeader && (
                <>
                  <Dropdown.Header icon="tags" content="Actions" />
                  <Dropdown.Divider />
                </>
              )}
              {options.map((option, key) => {
                if (
                  option.key === "start_task" &&
                  userAsMember?.grade === MemberOrganizationEnum.OBSERVER
                ) {
                  return null;
                }
                return (
                  <Dropdown.Item
                    disabled={
                      loading ||
                      (option.key === "start_task" &&
                        !(
                          activeTask &&
                          Array.isArray(activeTask.checklist) &&
                          activeTask.checklist.every((el) => el.isChecked)
                        ))
                    }
                    key={key}
                    {...option}
                  />
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        }
        pinned
        on="click"
        onClose={() => props.setTaskOptionsOpen(false)}
        trigger={<div
            style={{ cursor: "pointer"}}
            onClick={() => props.setTaskOptionsOpen(true)}
            onMouseEnter={(e) => {
                props.setTaskOptionsOpen(true)
                const pageHeight = window.innerHeight;
                const y = e.clientY;
                if (pageHeight - y < 200) {
                    setLowOnPage(true);
                } else {
                    setLowOnPage(false);
                }
            }}
            onMouseLeave={() => props.setTaskOptionsOpen(false)}
        >{props.children}</div>}
      />
        {/*{isModalOpened &&*/}
        {/*  <TaskEventModal*/}
        {/*    task={props.task}*/}
        {/*    opened={isModalOpened}*/}
        {/*    setOpened={setIsModalOpened}*/}
        {/*    eventText={eventText}*/}
        {/*    suspendReason={suspendReason}*/}
        {/*    evaluationResult={evaluationResult}*/}
        {/*    defaultDate={defDate}*/}
        {/*  />}*/}
    </>
  );
};
