import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Checkbox, CheckboxProps, FormTextArea, Header, Message, Modal} from 'semantic-ui-react';
import {TaskThunk} from '../../../../../store/thunk/task.thunk';
import TaskModel from '../../../../../models/responses/task.model';
import "react-datepicker/dist/react-datepicker.css";
import {useActiveProjectSelector} from "../../../../../store/selectors/project.selectors";

interface PropsModel {
    sliderValue: number,
    task: TaskModel,
    setShowModal: (val: boolean) => void,
    setProgressChanged: (val: boolean) => void
    setSliderValue: (val: number) => void
}

export default function TaskProgressModal (props: PropsModel) {
    const {sliderValue, task, setProgressChanged } = props;
    const [reason, setReason] = useState<any>('');
    const [finalValue, setFinalValue] = useState<number>(sliderValue);
    const activeProject = useActiveProjectSelector();

    const handleSubmit = () => {
        TaskThunk.updateTaskProgress(task, sliderValue, reason, activeProject?.snapshotDataVersionId ? activeProject.snapshotDataVersionId : null)
            .then(() => {
                setProgressChanged(false)
                props.setShowModal(false)
                props.setSliderValue(finalValue)
            })
            .catch((e) => console.error(e));
    }

    const handleClose = () => {
        props.setShowModal(false)
        setProgressChanged(false)
        if (!task.progress) {
            props.setSliderValue(0)
        } else {
            props.setSliderValue(task.progress.sort((a, b) => b.date.seconds - a.date.seconds)[0].progress)
        }
    }

    const handleSliderChange = (e: any) => {
        setFinalValue(e.target.value)
        props.setSliderValue(e.target.value)
    }

    return (
        <>
            <Modal dimmer='blurring' className="modal-event" open={true} closeIcon onClose={() => handleClose()}>
                <Header textAlign="center" content={"Task progress entry"} />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column', margin: "auto", padding: "10px", alignItems: "center"
                }}>
                    <h5>{`${task.task_code} - ${task.task_name}`}</h5>
                    {task.wbsPath && <p>{`(${task.wbsPath}) / ${task.wbs})`}</p>}
                    <h4 style={{marginTop: "5px"}}>{`You are progressing ${task.task_code} to ${sliderValue}% complete. Please add a comment and confirm.`}</h4>
                    <span><input type={'number'} onChange={handleSliderChange} value={finalValue} style={{width: "50px"}}/>% complete</span>
                </div>
                <FormTextArea placeholder={'Add comment...'}
                              style={{margin: 'auto', width: '310px', borderColor: '#bfbfbf'}}
                              value={reason !== '' ? reason : undefined}
                              onChange={(e, {value}) => setReason(value)}
                />
                <Modal.Actions>
                    <Button
                        onClick={() => handleClose()}
                        negative>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleSubmit()}
                        positive>
                        Confirm
                    </Button>
                </Modal.Actions>
            </Modal>

        </>
    )
}
